exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-companies-list-js": () => import("./../../../src/templates/companies-list.js" /* webpackChunkName: "component---src-templates-companies-list-js" */),
  "component---src-templates-company-detail-js": () => import("./../../../src/templates/company-detail.js" /* webpackChunkName: "component---src-templates-company-detail-js" */),
  "component---src-templates-funds-js": () => import("./../../../src/templates/funds.js" /* webpackChunkName: "component---src-templates-funds-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-insights-detail-js": () => import("./../../../src/templates/insights-detail.js" /* webpackChunkName: "component---src-templates-insights-detail-js" */),
  "component---src-templates-inthenews-detail-js": () => import("./../../../src/templates/inthenews-detail.js" /* webpackChunkName: "component---src-templates-inthenews-detail-js" */),
  "component---src-templates-our-people-detail-js": () => import("./../../../src/templates/our-people-detail.js" /* webpackChunkName: "component---src-templates-our-people-detail-js" */),
  "component---src-templates-our-people-listing-js": () => import("./../../../src/templates/our-people-listing.js" /* webpackChunkName: "component---src-templates-our-people-listing-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-press-release-detail-js": () => import("./../../../src/templates/press-release-detail.js" /* webpackChunkName: "component---src-templates-press-release-detail-js" */),
  "component---src-templates-press-release-js": () => import("./../../../src/templates/press-release.js" /* webpackChunkName: "component---src-templates-press-release-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-sitemap-js": () => import("./../../../src/templates/sitemap.js" /* webpackChunkName: "component---src-templates-sitemap-js" */)
}

