// custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"
// // normalize CSS across browsers
// import "./src/normalize.css"
// // custom CSS styles
import "./src/style.css"

import "/static/common/css/bootstrap.min.css" ;
import "/static/common/css/slick.css" ;
import "/static/common/css/slick-theme.css" ;
import "/static/common/css/mapplic.css" ;
import "/static/common/css/animate.css" ;
import "/static/common/css/default.css" ;
import "/static/common/css/media.css" ;
import "/static/common/css/local.css" ;

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    const currentPosition = getSavedScrollPosition(location)
    // console.log('shouldUpdateScroll', location);
     const { pathname,search,keyword } = location
    // list of routes for the scroll-to-top-hook
    //const scrollToTopRoutes = [`/portfolio`]
    // console.log("abc",pathname)

    // console.log("zyx",search)
    // if the new route is part of the list above, scroll to top (0, 0)
    if (pathname.indexOf('portfolio') !== -1 && search !== "" && search.indexOf('keyword')===-1) {
        // console.log('shouldUpdateScroll no')
        document.getElementById("allCompany").scrollIntoView()
        return false
    }
    else
    {
        return true
    }

    // return false
}

